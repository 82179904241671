<template>
  <div class="report-list-down">
    <back-title :title="title"></back-title>
    <div class="one-page">
      <div class="report-list-down__content flex-content">
        <template v-if="gameId === 99">
          <div class="report-list-down__comprehensive">
            <div class="comprehensive-chart" ref="comprehensiveRef"></div>
            <div class="comprehensive-info">
              <div class="info-item">
                <p class="title">
                  <span class="label">专注训练结果：</span>
                  <span class="result">稳定性</span>
                </p>
                <div class="text">
                  <p>反应较灵活型慢，细心，踏实，肯于钻研，思维准确，具有长时间稳定的工作能力。</p>
                  <p>学习动作慢于灵活型，但是掌握动作较牢固，不易忘记，消退，正常情况下临场发挥好，但应变能力不及灵活型。</p>
                </div>
              </div>
            </div>
          </div>
          <btn-tab></btn-tab>
        </template>
        <template v-else>
          <ul class="report-list-down__list">
            <li class="item" v-for="item in reportList" :key="item.id" @click="checkItem(item)">
              <img src="" alt="">
              <div class="info-box">
                <div class="info">
                  <div class="info-title">{{ item.title }}</div>
                  <div class="info-sub">
                    <span>{{ item.time }}</span> |
                    <span>测试等级：{{ item.level }}</span> |
                    <span>用时：{{ item.timeConsuming }}</span>
                  </div>
                </div>
                <div class="check-box">
                  <img v-if="item.isChecked" src="../assets/image/checked.png" alt="">
                  <img v-else src="../assets/image/empty.png" alt="">
                </div>
              </div>
            </li>
          </ul>
          <div class="down-load__btn">
            <div class="check-all" @click="checkAllItem">
              <img v-if="allChecked" src="../assets/image/checked.png" alt="">
              <img v-else src="../assets/image/empty.png" alt="">
              全选
            </div>
            <nut-button class="normal-btn" type="info" @click="downloadReport">下载</nut-button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Check } from '@nutui/icons-vue';
import { ref, toRefs, reactive, shallowReactive, computed, onMounted, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { useStore, mapState } from 'vuex'
import backTitle from "@/components/backTitle";
import btnTab from "@/components/btnTab";
import axios from 'axios';
import * as echarts from 'echarts'

const route = useRoute()
const router = useRouter()
const store = useStore()
const storeState = store.state

// let { gameList } = mapState(['gameList'])
// gameList = computed(gameList.bind({ $store: store }))

let gameId = Number(route.params.gameId || '0')
const gameName = [
  {
    id: 0,
    title: '专注力测试报告',
  },
  {
    id: 1,
    title: '灵活性测试报告',
  },
  {
    id: 2,
    title: '脑力反应测试报告',
  },
  {
    id: 99,
    title: '综合测试报告',
  },
]

const title = gameName.find(item => item.id === gameId).title
const allChecked = ref(false)
const reportList = reactive([])
const apiUrl = process.env.VUE_APP_API_URL

// 获取数据库中的报告列表
const fetchDataFromServer = async () => {
  const phoneNumber = storeState.phoneNumber
  if(!phoneNumber) {
    return
  }
  const token = storeState.openId
  const url = apiUrl + '/getData_by_openid?openId=' + token;
  try {
    const res = await axios.get(url);
    console.log(res.data.data);
    res.data.data.forEach((item, i) => {
      const tmp = {
        id: i + 1,
        title: title + (i + 1),
        time: new Date(new Date(item.TestDate).getTime() - 8 * 60 * 60 * 1000)
          .toLocaleDateString('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false // 使用24小时制
          }).replace(/\//g, '-'),       
        level: item.TestLevel,
        timeConsuming: item.UsageTime,
        score: item.score,
        userType: item.UserType,
        isChecked: false,
      };
      reportList.push(tmp);
    });
  } catch (err) {
    console.log(err);
    console.log(url, title);
  }
}

const checkItem = (item) => {
  item.isChecked = !item.isChecked
  allChecked.value = reportList.every(list => list.isChecked)
  router.push({
    name:'testReport',
    params: {
      gameId: gameId,
      difficultyInfo: item.level,
      timer: item.timeConsuming,
      score: item.score ? item.score: 90,
      userType: item.userType,
      testDate: item.time,
    }
  })
}

const checkAllItem = () => {
  allChecked.value = !allChecked.value
  reportList.forEach(item => {
    item.isChecked = allChecked.value
  })
}
const downloadReport = () => {}

const comprehensiveRef = ref(null)
const initChart = () => {
  let chartOption = {
    radar: {
      nameGap: 5,
      center: ['50%', '55%'],
      indicator: [
        { name: '专注力', max: 100 },
        { name: '灵活性', max: 100 },
        { name: '脑力反应', max: 100 },
        { name: '兴奋与抑制', max: 100 },
        { name: '心理综合', max: 100 }
      ],
      splitArea: {
        show: false
      },
      axisName: {
        fontSize: 10
      }
    },
    series: [
      {
        type: 'radar',
        symbol: 'none',
        data: [
          {
            value: [42, 50, 20, 30, 50, 80]
          },
          {
            value: [50, 14, 70, 26, 90, 21]
          }
        ]
      }
    ]
  }
  let myChart = echarts.init(comprehensiveRef.value)
  myChart.setOption(chartOption)

}
onMounted(() => {
  if (gameId === 99) {
    initChart()
  }
  else {
    fetchDataFromServer()
  }
})

</script>

<style scoped lang="less">
.report-list-down {
  width: 100%;
  height: 100%;

  .one-page >.flex-content {
    flex-direction: column;
  }

  .report-list-down__content {
    padding: 0.2rem 0.5rem;

    .report-list-down__comprehensive {
      flex: auto;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      .comprehensive-chart {
        width: 100%;
        height: 3rem;
      }
      .comprehensive-info {
        margin-top: 0.4rem;
        .info-item {
          font-size: 0.28rem;
          color: #666666;
          .title {
            font-size: 0.30rem;
            font-weight: bold;
            color: #333333;
            .result {
              color: #4886FF;
            }
          }
          .text {
            margin-top: 0.1rem;
            text-indent: 2em;
            line-height: 0.5rem;
          }
        }
      }
    }
    .btn-tab {
      margin-bottom: 0.3rem;
    }

    .report-list-down__list {
      flex: auto;
      width: 100%;
      overflow: auto;
      .item {
        width: 100%;
        display: flex;
        align-items: center;
        padding-top: 0.14rem;
        >img {
          width: 0.7rem;
          height: 0.7rem;
          border-radius: 50%;
          margin-right: 0.1rem;
          margin-bottom: 0.14rem;
        }
        .info-box {
          flex: 1;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #E5E5E5;
          padding-bottom: 0.14rem;
          .info {
            margin-right: 0.1rem;
            .info-title {
              font-size: 0.3rem;
              color: #4886FF;
              font-weight: bold;
            }
            .info-sub {
              font-size: 0.22rem;
              color: #BFC4CD;
            }
          }
          .check-box {
            width: 0.3rem;
            height: 0.3rem;
            margin-left: 0.14rem;
            >img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .down-load__btn {
      display: flex;
      justify-content: space-between;
      .check-all {
        display: flex;
        align-items: center;
        font-size: 0.28rem;
        color: #BFC4CD;
        >img {
          width: 0.3rem;
          height: 0.3rem;
          margin-right: 0.1rem;
        }
      }
      .normal-btn {
        width: 2.9rem;
        height: 0.88rem;
        font-size: 0.32rem;
      }
    }
  }
}
</style>
