<template>
  <div class="report-obtain">
    <back-title title="获取检测报告"></back-title>
    <div class="one-page">
      <div class="flex-content">
        <ul class="report-list">
          <li class="report-list_item" v-for="item in reportTypeList" :key="item.title">
            <nut-button class="normal-btn" :class="{ 'dis': !item.devEnd }" type="info" :disabled="!item.devEnd" @click="getReportList(item)">{{ item.title }}</nut-button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs, reactive, shallowReactive, computed, onMounted, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { useStore, mapState } from 'vuex'
import backTitle from "@/components/backTitle";
import axios from 'axios';

const route = useRoute()
const router = useRouter()
const store = useStore()
const storeState = store.state

// let { gameList } = mapState(['gameList'])
// gameList = computed(gameList.bind({ $store: store }))

const reportTypeList = [
  {
    id: 0,
    isGame: true,
    devEnd: true,
    title: '专注力测试报告查看',
  },
  {
    id: 1,
    isGame: true,
    devEnd: false,
    title: '灵活性测试报告查看',
  },
  {
    id: 2,
    isGame: true,
    devEnd: false,
    title: '脑力反应测试报告查看',
  },
  {
    id: 99,
    isGame: false,
    devEnd: false,
    title: '综合测试报告查看',
  },
]

const getReportList = (item) => {
  router.push({
    name: 'reportListDown',
    params: {
      gameId: item.id
    }
  })
}

</script>

<style scoped lang="less">
.report-obtain {
  width: 100%;
  height: 100%;

  .one-page >.flex-content {
    align-items: center;
    justify-content: center;
  }

  .report-list {
    width: 100%;
    margin: 0 0.5rem;
    .report-list_item {
      margin-bottom: 0.7rem;
      .normal-btn {
        //background: #4886FF;
        width: 100%;
        height: 0.8rem;
        border-radius: 6px;
        &:hover {
          border-color: transparent;
          background: #F4F5F6;
          color: #666666;
        }
      }
    }
  }

}
</style>
