<template>
  <div class="homeView">
   <div class="home-top">
     <p class="mb-word">“ 运动潜能 ”</p>
     <p class="mb-word">匹配大师</p>
     <p class="mb-subWord">发现你的内在体育天赋</p>
   </div>
    <template v-if="!showCode">
      <div class="home-bottom">
        <p class="line-title">
          <span class="left"></span>
          <span class="center">测试类型</span>
          <span class="right"></span>
        </p>
        <ul class="home-list">
          <li class="home-list_item" v-for="item in gameList" :key="item.title">
            <div class="left">
              <img :src="require('@/assets/image/' + item.img + '.png')" alt="">
            </div>
            <div class="right">
              <div class="item-name">
                <p class="item-title">{{ item.title }}</p>
                <p class="item-sub" v-if="item.sub">{{ item.sub }}</p>
              </div>
              <div class="item-group">
                <nut-button v-if="item.devEnd" class="normal-btn" type="info" @click="jumpGameIntro(item.id)">开始测试</nut-button>
                <nut-button v-else class="normal-btn dis" disabled>待开发</nut-button >
              </div>
            </div>
          </li>
        </ul>
        <span class="code-btn" @click="showCode = true">输入验证码</span>
      </div>
    </template>
    <template v-if="showCode">
      <div class="home-bottom">
        <p class="line-title">
          <span class="left"></span>
          <span class="center">输入验证码</span>
          <span class="right"></span>
        </p>
        <div class="enter-code">
          <div class="code-box">
            <input
                v-for="(code, index) in verificationCodes"
                :key="index"
                v-model="verificationCodes[index]"
                @input="handleInput(index, $event)"
                @keydown="handleKeyDown(index, $event)"
                maxlength="1"
                class="verification-input"
            />
          </div>
          <div class="code-tip">
            <span>  </span>
          </div>
          <!-- <div class="code-tip">
            <span>没收到验证码？<span>60</span>秒后联系客服</span>
          </div> -->
          <div class="btn-group">
            <nut-button @click="cancelCode" class="normal-btn cancel" type="info" plain>取消</nut-button>
            <nut-button @click="testReport" class="normal-btn confirm" type="info">确定</nut-button>
          </div>
        </div>
      </div>
    </template>
    <!--手机号登录-->
    <nut-popup position="bottom" v-model:visible="showPhone">
      <div class="phone-box">
        <div class="phone-top">
          <p class="title popup-title">登录</p>
          <p class="phone">{{phoneNumber}}</p>
          <p class="btn-box">
            <nut-button class="normal-btn" type="info" @click="phoneLogin">本机号码一键登录</nut-button>
          </p>
          <p class="agreement">
            <nut-radio-group v-model="agreement">
              <nut-radio label="true">我已阅读并同意《用户服务协议》和隐私政策</nut-radio>
            </nut-radio-group>
          </p>
        </div>
        <div class="cancel-div">
          <p @click="showPhone = false">取消</p>
        </div>
      </div>
    </nut-popup>
    <!--解锁测试-->
    <nut-popup v-model:visible="showUnlockTest" pop-class="unlock-popup">
      <div class="unlock-box">
        <p class="title popup-title">解锁测试</p>
        <p class="money">
          <span class="new-money">￥2.99</span>
          <span class="old-money">￥9.99</span>
        </p>
        <p class="tip">测试+完整解读+运动职业推荐/提升建议</p>
        <div class="btn-group">
          <nut-button @click="cancelUnlock" class="normal-btn cancel" type="info" plain>取消</nut-button>
          <nut-button @click="unlockTest" class="normal-btn confirm" type="info">确定</nut-button>
        </div>
      </div>
    </nut-popup>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { mapState } from 'vuex';
import axios from "axios";
import wx from 'weixin-js-sdk';
// import { Toast } from '@nutui/nutui';
const apiUrl = process.env.VUE_APP_API_URL;

export default {
  name: 'HomeView',
  components: {},
  data() {
    return {
      showPhone: false,
      showCode: false,
      agreement: false,
      verificationCodes: ['', '', '', '', '', ''],
      countdownCode: 60,
      showUnlockTest: false,
      phoneNumber: '',
      conNum: 0,
      flexNum: 0,
      menNum: 0,
    }
  },
  computed: {
    ...mapState(['gameList']),
    ...mapState(['ifInitialized']),
  },
  created() {
    // 如果是已初始化则直接返回
    if(this.ifInitialized){
      this.showPhone = false;
      return;
    }
    this.$store.commit('setIfInitialized', true)

    // 解析 URL 获取参数
    const url = window.location.href;
    const urlParams = new URL(url);
    const userName = urlParams.searchParams.get('username') || "";
    const avatar = urlParams.searchParams.get('avatar') || "";
    const openId = urlParams.searchParams.get('token');
    const phoneNumber = urlParams.searchParams.get('phone') || "";
    const isPhone = urlParams.searchParams.get('ifPhone');

    if(isPhone === '0')
      this.showPhone = false
    else{
      // mask middle 4 number
      if(phoneNumber.length === 11) {
        this.phoneNumber = phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
      } else {
        this.phoneNumber = phoneNumber;
      }
    }

    // 将参数存储为 Vue 的全局变量
    this.$store.commit('setUsername', userName);
    this.$store.commit('setAvatar', avatar);
    this.$store.commit('setOpenId', openId);
    this.$store.commit('setPhoneNumber', phoneNumber);

    // 提前发起 GET 请求 查询用户过关的关卡数
    const fetchDataFromServer = async () => {
      try {
        let url = apiUrl + '/getUserAge?token=' + openId;
        const response = await axios.get(url);
        if (response.data.sex !== "" && response.data.sex != null &&
            response.data.age !== "" && response.data.age != null){
          this.$store.commit('updateUserInfo', {
            sex: response.data.sex,
            age: response.data.age,
          })
        }
      } catch (error) {
        console.error('请求用户性别、年龄失败:', error);
      }
    };
    fetchDataFromServer();

    //从服务器获取用户购买信息
    this.getAccountData();

  },
  methods: {
    //获取账户可以玩的次数
    async getAccountData() {
      try {
        let url = `${apiUrl}/testInfo?telNumber=${this.$store.state.phoneNumber}`;
        const response = await axios.get(url);
        if (response.data !== "") {
          console.log(response.data);
          this.conNum = response.data.conNum;
          this.flexNum = response.data.flexNum;
          this.menNum = response.data.menNum;
        }
      } catch (error) {
        console.error('请求用户购买情况失败', error);
      }
    },

    // 微信支付解锁测试并跳转
    async navigateToPay(total_fee) {
      return new Promise(() => {
        wx.miniProgram.navigateTo({
          url: `/pages/pay/pay?total_fee=${total_fee}`,
        });
      });
    },

    async unlockTest() {
      this.cancelUnlock();
      try {
        const res = await axios.get(`${apiUrl}/get_price?gameid=1`);
        if (res.data.ret === 1) {
          await this.navigateToPay(res.data.newPrice);
        }
      } catch (err) {
        await this.navigateToPay(1);
      }
    },

    async jumpGameIntro(id) {
        // 获取测试报告直接跳转
        if (id === 'report') {
        this.$router.push({
          name: 'reportObtain'
        })
        return;
      }

      if (id !== '0'){
        return;
      }

      // 检查用户是否有购买次数
      await this.getAccountData()
      if (this.conNum < 1) {
        this.showUnlockTest = true
        return
      }


      this.$router.push({
        name: 'introduce',
        params: {
          gameId: id
        }
      })
    },
    phoneLogin() {
      this.showPhone = false
      this.showCode = false
    },
    handleInput(index, event) {
      const value = event.target.value
      this.verificationCodes[index] = value

      // 判断是否输入完成
      if (this.verificationCodes.join('').length === 6) {
        console.log(112233)
      }

      // 自动跳到下一个输入框
      if (value && index < this.verificationCodes.length - 1) {
        const nextInput = event.target.nextElementSibling
        if (nextInput) {
          nextTick(() => {
            nextInput.focus()
          })
        }
      }
    },
    handleKeyDown(index, event) {
      // 处理删除操作
      if (event.key === 'Backspace' && !event.target && index > 0) {
        const prevInput = event.target.previousElementSibling
        if (prevInput) {
          nextTick(() => {
            prevInput.focus()
          })
        }
      }
    },
    cancelCode() {
      this.showCode = false
      this.countdownCode = 60
    },
    async testReport() {
      // verfication code
      const code = this.verificationCodes.join('')
      if (code.length !== 6) {
        // Toast.text('请输入正确的验证码')
        window.alert('请输入正确的验证码')
        return
      }
      await this.verifyCodes(code)
    },
    async verifyCodes(code) {
      try{
        let url = apiUrl + '/verifyVerCode';
        const response = await axios.post(url, {verCode: code, telNumber: this.$store.state.phoneNumber})
        if(response.data.ret === 1){
          console.log(response.data.ret)
          window.alert('验证成功!')
          this.showCode = false
          // this.$toast('验证码成功!')
        }
      }catch(error){
        console.error('验证码验证错误', error)
        window.alert('验证码验证错误，请重新输入验证码')
        // this.$toast('验证码验证错误，请重新输入验证码')
      }
    },

    cancelUnlock() {
      this.showUnlockTest = false
    },

  }
}
</script>

<style scoped lang="less">
.homeView {
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  background: url("../assets/image/bg.png") no-repeat;
  background-size: 100% 100%;
  background-color: #fff;
  .home-top {
    flex: none;
    height: 35%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .home-bottom {
    flex: auto;
    width: 100%;
    margin-top: 0.3rem;
    padding-bottom: 0.3rem;
    overflow: auto;
    position: relative;
    .home-list {
      width: 84%;
      margin: 0.3rem auto 0;
      .home-list_item {
        padding: 0.3rem 0.3rem 0.2rem;
        display: flex;
        background: #FFFFFF;
        box-shadow: 0 0 0.1rem 0 rgba(0,0,0,0.15);
        border-radius: 0.16rem;
        margin-bottom: 0.2rem;
        .left {
          flex: none;
          margin-right: 0.5rem;
          img {
            width: 1.4rem;
            height: 1.4rem;
            border-radius: 0.16px;
          }
          //aspect-ratio: 1;
        }
        .right {
          flex: auto;
          display: flex;
          flex-direction: column;
          .item-name {
            flex: auto;
            .item-title {
              font-size: 0.3rem;
              color: #222;
              font-weight: bold;
            }
            .item-sub {
              margin-top: 0.1rem;
              display: inline-block;
              font-size: 0.2rem;
              color: #3860F4;
              padding: 1px 0.2rem;
              background: rgba(72,134,255,0.10);
              border-radius: 0.15rem;
            }
          }
          .item-group {
            text-align: right;
          }
        }
      }
    }
    .code-btn {
      position: absolute;
      top: 0.04rem;
      right: 8%;
      font-size: 0.26rem;
      color: #4886FF;
    }
  }
  .enter-code {
    text-align: center;
    .code-box {
      margin-top: 0.5rem;
      display: flex;
      justify-content: center;
      input {
        padding: 0;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 0.08rem;
        background: #F4F5F6;
        margin: 0 0.05rem;
        border: none;
        outline: none;
        font-size: 0.5rem;
        color: #505152;
        text-align: center;
      }
    }
    .code-tip {
      font-size: 0.24rem;
      color: #B4B5B7;
      margin: 0.2rem 0 0.4rem;
    }
    .btn-group {
      .normal-btn {
        width: 2.4rem;
        height: 0.8rem;
        border-radius: 0.12rem;
      }
      .cancel {
        margin-right: 0.3rem;
      }
      .confirm {
        background: #4886FF;
      }
    }
  }
  .phone-box {
    background: #F4F5F6;
    font-size: 0.3rem;
    text-align: center;
    .phone-top {
      background: #fff;
      padding: 0.2rem 0.7rem;
      .title {
        margin-bottom: 0.3rem;
      }
      .phone {
        font-size: 0.5rem;
        color: #505152;
      }
      .btn-box {
        width: 100%;
        >.normal-btn {
          width: 100%;
          height: 0.8rem;
          font-size: 0.3rem;
          background: #4886FF;
          margin: 0.4rem 0 0.3rem;
        }
      }
      .agreement {
        ::v-deep(.nut-radio__icon) {
          color: #4886FF;
        }
        ::v-deep(.nut-radio__label) {
          margin-left: 0.1rem;
          font-size: 0.24rem;
          color: #B4B5B7;
          line-height: 1;
        }
      }
    }
    .cancel-div {
      margin-top: 0.16rem;
      background: #fff;
      //padding-bottom: 0.5rem;
      >p {
        padding: 0.3rem;
      }
    }
  }
  .unlock-box {
    padding: 0.4rem;
    text-align: center;
    .title {
      margin-bottom: 0.24rem;
    }
    .money, .tip {
      font-size: 0.28rem;
      color: #999999;
      .new-money {
        color: #F34C36;
      }
      .old-money {
        color: #999999;
      }
    }
    .btn-group {
      margin-top: 0.48rem;
      .normal-btn {
        width: calc(50% - 0.15rem);
        height: 0.8rem;
        border-radius: 0.12rem;
      }
      .cancel {
        margin-right: 0.3rem;
      }
      .confirm {
        background: #4886FF;
      }
    }
  }
}
::v-deep(.unlock-popup) {
  width: 80%;
}
</style>
