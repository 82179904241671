<template>
  <div class="flexibility-game">
    <back-title :title="pageTitle"></back-title>
    <div class="one-page">
      <div class="schulte-content  ready-content flex-content">
        <div class="game-ready" v-if="gameStatus === 'ready'">

        </div>
        <div class="game-start" v-if="gameStatus === 'start' || gameStatus === 'end'">
          <div class="top-word">
            <p class="tip" v-html="gameTip"></p>
            <p class="time">倒计时：{{ gameTime }}s</p>
          </div>
          <div class="game-box">
            <div class="game-warp" ref="gameWarp">
              <div class="games-bottle" :style="{ width: computedGbWidth }">
                <div
                    v-for="(item, index) in gameArr"
                    :key="index"
                    @click="gameBottleClick(item)"
                    class="bottle"
                    :class="{ isClicked: item.isClicked && !item.isError, isError: item.isError  }"
                    :style="{ width: computedBWidth }"
                >{{ item.num }}</div>
              </div>
            </div>
          </div>
          <div class="bottom-group">
            <p class="operation">
              <nut-button @click="reStart" class="normal-btn reStart" type="info" plain>重新测试</nut-button>
              <nut-button @click="completeGame" class="normal-btn" type="info" :disabled="isButtonDisabled">完成</nut-button>
            </p>
            <p @click="goBack" class="back-line"><span>返回</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--检测报告-->
  <report-popup v-if="showReportPopup" @reporting="reporting"></report-popup>
</template>

<script setup>
import {ref, toRefs, reactive, shallowReactive, computed, onMounted, onBeforeUnmount, nextTick} from 'vue';
import {useRoute, useRouter} from 'vue-router'
import {useStore} from 'vuex'
import {showDialog} from '@nutui/nutui';
import backTitle from "@/components/backTitle";
import reportPopup from "@/components/reportPopup";
import axios from "axios";

const route = useRoute()
const router = useRouter()
const store = useStore()
const storeState = store.state

let gameId = ref(route.params.gameId || '0')
let difficulty = ref(Number(route.params.difficulty || '0')) // 难度即方格行列数
const stateInfo = storeState.gameList[gameId.value]
const pageTitle = ref(stateInfo.gameName)
let showReportPopup = ref(false) // 检测报告弹框

const goBack = () => {
  router.go(-1)
}

let userType = ref("") // 用户的类型
const userName = storeState.userName
const reportInfo = storeState.reportDetails
const sex = storeState.userInfo.sex
const age = storeState.userInfo.age

let gameStatus = ref('start') // 游戏状态：ready start end
let gameTime = ref(70) // 游戏总时长
const gameWarp = ref(null)


let col = 6 // 列数
// 游戏布局宽
const computedGbWidth = computed(() => {
  if (gameWarp.value) {
    let itemWidth = Math.floor(gameWarp.value.clientWidth / col)
    let width = itemWidth * 9 + 1
    return width + 'px'
  }
  return `calc(100%)`
})
// 游戏格子宽
const computedBWidth = computed(() => {
  // if (gameWarp.value) {
  //   let itemWidth = Math.floor(gameWarp.value.clientWidth / col)
  //   return itemWidth + 'px'
  // }
  return `calc(100% / ${ col })`
})

let gameArr = ref([])
// 规则列表
const gameRuleObj = reactive({
  '3': '',
  '4': '',
  '5': '',
  '6': '',
  '7': '',
})
// 当前难度规则
let activeRule
const judeRule = (difficulty, num1, num2) => {
  return function (item) {
    if (difficulty === 3) {
      return item.num === num1
    }
    // if (difficulty === 4) {}
    // if (difficulty === 5) {}
    // if (difficulty === 6) {}
    if (difficulty === 7) {
      return item.num === num1
    }
  }
}
// 游戏提示
let gameTip = ref('')
// 游戏规则
const gameRule = (difficulty) => {
  let tip = ''
  let num = ''
  if (difficulty === 3) {
    num = Math.floor(Math.random() * 10)
    tip = `从左至右依次点击数字<span class="num">${ num }</span>`
    activeRule = judeRule(difficulty, num)
  }
  if (difficulty === 4) {
    return {}
  }
  if (difficulty === 5) {
    return {}
  }
  if (difficulty === 6) {
    return {}
  }
  if (difficulty === 7) {
    let firstNum = Math.floor(Math.random() * 10)
    let min
    let max
    let oddOrEven = firstNum % 2 === 0 ? '偶数' : '奇数'
    if (firstNum < 5) {
      min = firstNum
      max = min + 4
    } else {
      max = firstNum
      min = max - 4
    }
    tip = `从左至右依次点击 <span class="num">${ min }</span> 和 <span class="num">${ max }</span> 中间的 <span class="num">${ oddOrEven }</span>`
    num = min + 2
    activeRule = judeRule(difficulty, num)
  }
  gameTip.value = tip
  gameRuleObj[difficulty] = num
}
const initGame = (difficulty) => {
  let row = 100
  let col = 9
  let arr = []
  gameRule(difficulty)
  for (let i = 0; i < row * col; i++) {
    arr.push({
      num: Math.floor(Math.random() * 10),
      isError: false,
      isClicked: false
    })
  }
  gameArr.value = arr
}



const gameBottleClick = (item) => {
  if (item.isClicked) return
  item.isClicked = true
  if (!activeRule(item)) item.isError = true
}

onMounted(() => {
  initGame(difficulty.value)
})
const isButtonDisabled = ref(true)
// 重新测试
const reStart = () => {
  router.go(-1)
}
// 完成
const completeGame = () => {
  showReportPopup.value = true
}
</script>

<style scoped lang="less">
.flexibility-game {
  width: 100%;
  height: 100%;
  overflow: auto;

  .game-start {
    .game-box {
      flex: auto;
      overflow: auto;

      .game-warp {
        //aspect-ratio: 1;
        width: 100%;
        height: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        .games-bottle {
          width: 100%;
          height: 100%;
          overflow: auto;
          margin: auto;
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          border-top: 1px solid rgba(80, 81, 82, 1);
          border-left: 1px solid rgba(80, 81, 82, 1);

          .bottle {
            aspect-ratio: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid rgba(80, 81, 82, 1);
            border-bottom: 1px solid rgba(80, 81, 82, 1);
            font-size: 0.5rem;
            color: #505152;
            font-weight: bold;

            &.isClicked {
              color: #3B7DFF;
            }

            &.isError {
              color: #ff8c90;
            }
          }
        }
      }
    }
  }

  .tip {
    margin: 0.6rem 0;
    font-size: 0.36rem;
    font-weight: bold;
    text-align: center;
    :deep(.num) {
      color: #4886FF;
    }
  }

}
</style>
<style lang="less">
.timerOverDialog {
  .nut-dialog__footer {
    .nut-dialog__footer-ok {
      background: #4886FF;
    }
  }
}
</style>
