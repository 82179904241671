<template>
  <div class="test-report">
    <back-title title="检测报告"></back-title>
    <div class="one-page">
      <div class="report-content flex-content">
        <div class="report-word">
          <div class="word-info">
            <p>测试时间：<span>{{testDate}}</span></p>
            <p>测试项目：<span>专注力-</span><span>{{resultWord[typeIndex].difficultyInfo}}</span></p>
          </div>
          <p class="word-title">您本次专注力测试训练分数为<span class="normal-color-b">{{ resultWord[typeIndex].score }}</span>分</p>
          <p class="word-result normal-color-b">检测结果：<span>{{ resultWord[typeIndex].type }}</span></p>
          <div class="word-illustrate">{{ resultWord[typeIndex].illustrate }}</div>
          <div class="word-module">
            <p class="title normal-color-b">结果分析：</p>
            <div class="word" v-for="(item, index) in resultWord[typeIndex].resultAnalysis" :key="index">
              <span class="label normal-color-b">{{ item.label }}</span>
              <span>{{ item.info }}</span>
            </div>
          </div>
          <div class="word-module">
            <p class="title normal-color-b">专注力发展建议：</p>
            <div class="word" v-for="(item, index) in resultWord[typeIndex].concentration" :key="index">
              <span class="label normal-color-b">{{ item.label }}</span>
              <template v-if="typeof item.info === 'string'">
                <span>{{ item.info }}</span>
              </template>
              <template v-if="typeof item.info === 'object'">
                <p v-for="(sub, subIndex) in item.info" :key="subIndex">
                  {{ sub }}
                </p>
              </template>
            </div>
          </div>
          <div class="word-module">
            <p class="title normal-color-b">必要时的进一步评估或专业咨询推荐：</p>
            <div class="word">
              <span>{{ resultWord[typeIndex].consultation }}</span>
            </div>
          </div>
          <div class="word-module">
            <p class="title normal-color-b">结论：</p>
            <div class="word">
              <span>{{ resultWord[typeIndex].conclusion }}</span>
            </div>
          </div>
        </div>
        <btn-tab location="testReport"></btn-tab>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, toRefs, reactive, shallowReactive, computed, onMounted, nextTick} from 'vue';
import {useRoute, useRouter} from 'vue-router'
import {useStore} from 'vuex'
import backTitle from "@/components/backTitle";
import btnTab from "@/components/btnTab";

const route = useRoute()
const router = useRouter()
const store = useStore()
const storeState = store.state
const reportInfo = storeState.reportDetails

let gameId = ref(route.params.gameId || '0')
let difficultyInfo = ref(route.params.difficultyInfo || '0')
let timer = ref(route.params.timer || '0')
let score = ref(route.params.score || '0')
let userType = ref(route.params.userType || '稳定型')
let testDate = ref(route.params.testDate)

const allSportList = [
  {
    img: 'grf',
    name: '高尔夫'
  },
  {
    img: 'jz',
    name: '举重'
  },
  {
    img: 'yy',
    name: '游泳'
  },
  {
    img: 'tc',
    name: '体操'
  },
  {
    img: 'tg',
    name: '跳高'
  },
  {
    img: 'ty',
    name: '跳远'
  },
  {
    img: 'sj',
    name: '射击'
  },
  {
    img: 'pb',
    name: '跑步'
  },
  {
    img: 'qx',
    name: '骑行'
  },
  {
    img: 'sj2',
    name: '射箭'
  },
  {
    img: 'lq',
    name: '篮球'
  },
  {
    img: 'zq',
    name: '足球'
  },
  {
    img: 'bq',
    name: '棒球'
  },
  {
    img: 'ymq',
    name: '羽毛球'
  },
  // {
  //   img: 'ql',
  //   name: '球类'
  // },
  {
    img: 'qq',
    name: '铅球'
  },
  {
    img: 'pq',
    name: '排球'
  },
  {
    img: 'wq',
    name: '网球'
  },
  {
    img: 'yy2',
    name: '游泳长'
  },
  {
    img: 'sj3',
    name: '射击速'
  },
]

// 不同类型用户对应的图片列表
const userTypeImgList = [
  {
    name: '稳定型',
    type: 'stabilize',
    imgList: ['高尔夫', '举重', '游泳长', '体操', '跳高', '跳远', '射击', '射箭']
  },
  {
    name: '亚稳定型',
    type: 'metastable',
    imgList: ['高尔夫', '篮球', '足球', '网球', '棒球', '排球', '羽毛球']
  },
  {
    name: '兴奋型',
    type: 'exciting',
    imgList: ['球类', '体操', '游泳', '跑步', '铅球', '举重', '射击速']
  }
]
const sportList = ref([])

const levelList = [{name: '初级'}, {name: '中级'}, {name: '高级'}, {name: '困难'}]

const getSportList = () => {
  let getUserTypeImg = userTypeImgList.find(item => item.name === userType.value)
  if (getUserTypeImg) {
    let userTypeImg = getUserTypeImg.imgList
    sportList.value = allSportList.reduce((prev, cur) => {
      let isHave = userTypeImg.includes(cur.name)
      if (isHave) {
        prev.push(cur)
      }
      return prev
    }, [])
  }
}

onMounted(() => {
  // getSportList()
})

const getLevel = (score) => {
  score = parseInt(score);
  if(score < 60)
    return 2;
  else if(score < 80)
    return 1;
  else
    return 0;
}

const resultWord = [
  {
    type: '优秀',
    key: 'excellent',
    difficultyInfo: difficultyInfo.value,
    score: score.value,
    illustrate: '专注力持续时间：受测者能持续专注的时间远超过同龄平均水平，表现出色。反应时间：受测者的反应时间快于同龄平均水平，显示出良好的反应能力。',
    resultAnalysis: [
      {
        label: '1、专注力水平解读：',
        info: '根据受测者的表现，其专注力水平属于较高水平。能够持续专注的时间长，反应时间快，显示了良好的注意力控制和信息处理能力。'
      },
      {
        label: '2、持续时间：',
        info: '受测者的专注力持续时间比同龄平均水平长，这表明其在持续注意和任务持续执行方面具有较强的能力。'
      },
      {
        label: '3、反应时间：',
        info: '相比于同龄人群的平均反应时间，受测者展现出更快的反应速度，这反映了其较高的认知处理速度和效率。'
      },
    ],
    concentration: [
      {
        label: '1、根据检测结果的个性化建议',
        info: '考虑到受测者展现出的优秀专注力，建议保持目前的专注力水平，并探索进一步提升的可能性。可以定期进行更具挑战性的专注力训练，比如复杂的逻辑思维游戏或多任务处理练习。'
      },
      {
        label: '2、可实施的专注力提升活动或练习',
        info: [
            '（1）心理训练活动：如瑜伽、冥想，这些活动有助于提高心智控制能力和集中注意力的能力。',
            '（2）认知练习：参与记忆力、注意力转换、快速反应等认知训练，以增强大脑的信息处理能力。',
            '（3）日常习惯：在日常生活中练习专注力，例如在阅读或完成学校作业时设定专注时间，减少干扰。',
        ]
      },
    ],
    consultation: '虽然当前的专注力水平优秀，但建议定期进行专注力评估，以监测和维持其发展。如果在未来的某个阶段感到专注力有所下降或遇到相关挑战，建议咨询专业人士，如心理学家或教育顾问，以获得更具针对性的指导和支持。',
    conclusion: '在本次检测中，受测者的专注力水平被评定为“优秀”，表明其在持续专注和快速反应方面的能力高于同龄人平均水平。这种高水平的专注力对学习、工作和日常生活都是极大的资产。针对未来的专注力发展，我们预期受测者将继续展示出色的表现。建议受测者继续培养和练习专注力，同时也探索提高其他认知技能的方法。通过这样的努力，受测者不仅能够维持当前的高水平，还能在面对新的挑战时更加自信和有效率。',
  },
  {
    type: '良好',
    key: 'good',
    difficultyInfo: difficultyInfo.value,
    score: score.value,
    illustrate: '专注力持续时间：受测者的专注力持续时间优于同龄平均水平，表现良好。 反应时间：受测者的反应时间与同龄平均水平相当，表现稳定。',
    resultAnalysis: [
      {
        label: '1、专注力水平解读：',
        info: '受测者的专注力水平良好。虽然专注时间优于平均水平，但反应速度上还有提升空间。'
      },
      {
        label: '2、持续时间：',
        info: '受测者能够超过同龄平均水平持续专注，这说明其在持续注意和任务执行方面表现良好。'
      },
      {
        label: '3、反应时间：',
        info: '受测者的反应时间与同龄人群平均水平相当，显示出稳定的认知处理能力。'
      },
    ],
    concentration: [
      {
        label: '1、根据检测结果的个性化建议',
        info: '鉴于受测者的专注力水平良好，建议其在现有基础上进一步提升专注力。适当增加专注力训练的难度和多样性，如进行时间管理和注意力分配训练。'
      },
      {
        label: '2、可实施的专注力提升活动或练习',
        info: [
          '（1）心理训练活动：参与如冥想、简易瑜伽等活动，有助于提高专注力和心理调节能力。',
          '（2）认知练习：进行基础的记忆力和注意力转换练习，以增强认知灵活性和反应速度。',
          '（3）日常习惯：在日常生活中设置小目标，如专注读书或完成作业一定时间，以此来训练专注力。',
        ]
      },
    ],
    consultation: '建议定期复评以监控专注力的变化和进步。如果遇到专注力下降或其他相关挑战，可考虑寻求心理学家或教育顾问的专业意见和指导。',
    conclusion: '在本次检测中，受测者的专注力水平被评定为“良好”。虽然在某些方面（如反应时间）与同龄人持平，但在持续专注方面表现较好，这对其学习和日常活动是有益的。针对未来的专注力发展，建议受测者继续锻炼和提升专注力，特别是在反应速度方面。通过持续的练习和适当的策略，受测者有潜力进一步提高其专注力，从而更好地应对日常生活和学习中的挑战。',
  },
  {
    type: '一般',
    key: 'general',
    difficultyInfo: difficultyInfo.value,
    score: score.value,
    illustrate: '受测者的专注力持续时间接近同龄平均水平，但有改进的空间。 反应时间：受测者的反应时间略低于同龄平均水平。',
    resultAnalysis: [
      {
        label: '1、专注力水平解读：',
        info: '受测者的专注力水平评定为一般。虽然能够完成任务，但在持续专注和快速反应方面需要进一步提升。'
      },
      {
        label: '2、持续时间：',
        info: '受测者在专注力持续时间上与同龄平均水平相近，表明其专注力的持久性有待提高。'
      },
      {
        label: '3、反应时间：',
        info: '受测者的反应时间稍微低于平均水平，这暗示其信息处理速度和反应能力有改善的空间。'
      },
    ],
    concentration: [
      {
        label: '1、根据检测结果的个性化建议',
        info: '鉴于受测者的专注力水平一般，建议其采取措施提升专注力。适量增加专注力训练的频率和范围，例如进行简单的注意力集中训练和反应速度练习。'
      },
      {
        label: '2、可实施的专注力提升活动或练习',
        info: [
          '（1）心理训练活动：参加基本的放松和冥想活动，以提高专注力和减轻压力。',
          '（2）认知练习：进行基础的记忆力训练和简单的注意力任务，以帮助提升专注力和认知反应。',
          '（3）日常习惯：在日常活动中实行短时间专注练习，如集中注意力阅读或完成家庭作业。',
        ]
      },
    ],
    consultation: '推荐定期进行专注力复评，以监测进步情况。如遇到持续的专注力困难，建议咨询专业人士，如心理学家或教育顾问，以获得更深入的指导和帮助。',
    conclusion: '在本次检测中，受测者的专注力水平被评定为“一般”，意味着其在持续专注和快速反应方面有待提升。这对于学习和日常活动可能构成一定的挑战。未来的专注力发展需要受测者更加努力。建议通过有针对性的训练和日常练习来增强专注力，特别是在持续注意和反应速度方面。随着这些能力的提升，受测者将更好地应对学业和生活中的各种挑战。',

  },
]

let typeIndex = ref(getLevel(score.value))


// 根据 userType 获取对应的报告详情
function userReport(userType) {
  return reportInfo.find(detail => detail.type === userType) || {};
}
let reportDetail = userReport(userType.value)
let title = ref(reportDetail.title || '')
let sub = ref(reportDetail.sub || '')

</script>

<style scoped lang="less">
.test-report {
  width: 100%;
  height: 100%;
  overflow: auto;

  .report-content {
    padding: 0.2rem 0.5rem 0.5rem;
    flex-direction: column;
    line-height: 0.54rem;

    .report-word {
      flex: auto;
      overflow: auto;
      font-size: 0.30rem;

      .word-info {
        font-size: 0.28rem;
        color: #BFC4CD;
        text-align: left;
      }

      .word-title {
        margin-top: 0.2rem;
        color: #333333;
        font-weight: bold;
        text-align: center;
      }
      .word-result {
        margin-top: 0.2rem;
        text-align: center;
      }
      .word-illustrate {
        margin: 0.2rem 0;
        color: #BFC4CD;
      }
      .word-module {
        .word {
          color: #333333;
        }
      }

    }

    .btn-tab {
      padding-top: 0.3rem;
    }
  }
}
</style>
