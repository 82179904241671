<template>
  <div class="level-change">
    <back-title :title="stateInfo.gameName"></back-title>
    <div class="one-page">
      <div class="level-content flex-content">
        <div v-for="item in stateInfo.levelList" :key="item.level" class="level-item">
          <nut-button @click="changeDifficulty(item)" class="normal-btn" type="info" plain>{{ item.name }}</nut-button>
          <p class="item-tip">{{ item.tip }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs, reactive, shallowReactive, computed, onMounted, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import backTitle from "@/components/backTitle";

const route = useRoute()
const router = useRouter()
const store = useStore()
const storeState = store.state

let gameId = ref(route.params.gameId || '0')
const stateInfo = storeState.gameList[gameId.value]
const changeDifficulty = (item) => {
  router.push({
    name: 'game' + gameId.value,
    params: {
      gameId: gameId.value,
      difficulty: item.level
    }
  })
}

</script>

<style scoped lang="less">
.level-change {
  width: 100%;
  height: 100%;
  overflow: auto;
  .level-content {
    padding: 0 0.5rem;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .level-item {
      &:not(:last-child) {
        margin-bottom: 0.6rem;
      }
      .item-tip {
        margin-top: 0.15rem;
      }
    }
    .normal-btn {
      width: 100%;
      padding: 0.24rem;
      &:hover {
        background: #4886FF;
        color: #fff;
      }
    }
  }
}
</style>
