import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import verifyLogin from '../views/verifyLogin.vue'
import introduce from '../views/introduce.vue'
import levelChange from '../views/levelChange.vue'
import game0 from '../views/game/schulteGame.vue'
import game1 from '../views/game/flexibilityGame.vue'
import game2 from '../views/game/reactionGame.vue'
import getScore from '../views/getScore.vue'
import testReport from '../views/testReport.vue'
import reportObtain from '../views/reportObtain.vue'
import reportListDown from '../views/reportListDown.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    props: true // 启用 props，将路由参数作为组件的 props 传递
  },
  {
    path: '/verifyLogin',
    name: 'verifyLogin',
    component: verifyLogin
  },
  {
    path: '/introduce/:gameId?',
    name: 'introduce',
    component: introduce
  },
  {
    path: '/levelChange/:gameId?',
    name: 'levelChange',
    component: levelChange
  },
  {
    path: '/game0/:gameId?/:difficulty',
    name: 'game0',
    component: game0
  },
  {
    path: '/game1/:gameId?/:difficulty',
    name: 'game1',
    component: game1
  },
  {
    path: '/game2/:gameId?/:difficulty',
    name: 'game2',
    component: game2
  },
  {
    path: '/getScore/:gameId?/:difficultyInfo/:timer/:score/:userType/:levelData',
    name: 'getScore',
    component: getScore
  },
  {
    path: '/testReport/:gameId?/:difficultyInfo/:timer/:score/:userType/:testDate',
    name: 'testReport',
    component: testReport
  },
  {
    path: '/reportObtain',
    name: 'reportObtain',
    component: reportObtain
  },
  {
    path: '/reportListDown/:gameId',
    name: 'reportListDown',
    component: reportListDown
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
