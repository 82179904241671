import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@nutui/nutui/dist/style.css";
import './assets/js/rem'
import './assets/js/wx'
import './assets/css/index.less'
import './assets/font/iconfont.css'
import { Button, Dialog, Cell, Picker, Popup, Radio, RadioGroup, Input } from "@nutui/nutui";


createApp(App).use(store).use(router).use(Button).use(Dialog).use(Cell).use(Picker).use(Popup).use(Radio).use(RadioGroup).use(Input).mount('#app')
