<template>
  <div class="schulte-game">
    <back-title :title="pageTitle"></back-title>
    <div class="one-page">
      <div class="schulte-content flex-content">
        <div class="game-ready" v-if="gameStatus === 'ready'">
          <p class="line-title">
            <span class="left"></span>
            <span class="center">方法</span>
            <span class="right"></span>
          </p>
          <p class="tip">从小到大点击所有数字</p>
          <div class="ready-ask">
            <p class="first">要求：</p>
            <p>1、视点自然放在表中心</p>
            <p>2、尽量减少眨眼次数、尽量不要转动眼球</p>
            <p>3、若数字的展示为<span style="color: #ff8c90;">红色</span>，重新点击正确数字即可</p>
          </div>
          <div class="ready-time">
            <nut-button @click="startGame" class="normal-btn" type="info">开始</nut-button>
            <!--            <p>READY</p>-->
            <!--            <p>-->
            <!--              <span>{{ countdown }}</span>-->
            <!--            </p>-->
          </div>
        </div>
        <div class="game-start" v-if="gameStatus === 'start' || gameStatus === 'end'">
          <div class="top-word">
            <p class="tip">请集中注意力！</p>
            <p class="time">计时：{{ gameTime }}s</p>
          </div>
          <div class="game-box">
            <div class="game-warp" ref="gameWarp">
              <div class="games-bottle" :style="{ width: computedGbWidth, height: computedGbWidth }">
                <div
                    v-for="item in gameArr"
                    @click="gameBottleClick(item)"
                    :style="{ width: computedBWidth(), height: computedBWidth() }"
                    :class="{ isClicked: item < gameArrSorted[current], isError: errorNum === item }"
                    class="bottle"
                    :key="item"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-group">
            <p class="operation">
              <nut-button @click="reStart" class="normal-btn reStart" type="info" plain>重新测试</nut-button>
              <!--<nut-button @click="completeBtn" class="normal-btn" type="info" :disabled="isButtonDisabled">完成</nut-button>-->
              <nut-button @click="completeBtn" class="normal-btn" type="info">完成</nut-button>
            </p>
            <p @click="goBack" class="back-line"><span>返回</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--检测报告-->
  <report-popup v-if="showReportPopup" @reporting="reporting"></report-popup>
</template>

<script setup>
import {ref, toRefs, reactive, shallowReactive, computed, onMounted, onBeforeUnmount, nextTick} from 'vue';
import {useRoute, useRouter} from 'vue-router'
import {useStore} from 'vuex'
import {showDialog} from '@nutui/nutui';
import backTitle from "@/components/backTitle";
import reportPopup from "@/components/reportPopup";
import axios from "axios";

const route = useRoute()
const router = useRouter()
const store = useStore()
const storeState = store.state

let gameId = ref(route.params.gameId || '0')
let difficulty = ref(Number(route.params.difficulty || '0')) // 难度即方格行列数
const stateInfo = storeState.gameList[gameId.value]
const pageTitle = ref(stateInfo.gameName)
let showReportPopup = ref(false) // 检测报告弹框

let total = ref(difficulty.value * difficulty.value) // total代表格子总数，为行列的平方
let score = ref(0) // 用户得分
let userType = ref("") // 用户的类型
let difficultyInfo = ref("")

const userName = storeState.userName
const openId = storeState.openId
const reportInfo = storeState.reportDetails
const sex = storeState.userInfo.sex
const age = storeState.userInfo.age

let gameStatus = ref('ready') // 游戏状态：ready start end
let isButtonDisabled = true //完成按钮初始为禁用，直到用户完成游戏
// 游戏准备阶段
let countdown = ref(5) // 倒计时开始时间
let timerReady = null
const readyToGame = () => {
  timerReady = setInterval(() => {
    countdown.value--
    if (countdown.value === 0) {
      startGame()
    }
  }, 1000)
}
// 游戏开始
let gameArr = ref([])
let gameArrSorted = ref([])
const stirGameArr = () => {
  let gameArrLen = total.value
  while (gameArr.value.length < gameArrLen) {
    var r = Math.floor(Math.random() * 50) + 1;
    // 不允许重复
    if (!gameArr.value.includes(r)) {
      gameArr.value.push(r);
    }
  }
  gameArrSorted.value = Array.from(gameArr.value);
  gameArrSorted.value = gameArrSorted.value.sort(function (a, b) {
    return a - b;
  });
}
// 发起 GET 请求 查询用户过关的关卡数
const apiUrl = process.env.VUE_APP_API_URL

const fetchDataFromServer = async () => {
  try {
    let url = apiUrl + '/getCount?token=' + openId + '&testType=' + pageTitle.value + ''
        + '&testLevel=' + difficultyInfo.value;
    const response = await axios.get(url);

    // 如果请求成功，将数据存储到 data 变量中
    levelData.value = response.data.count + 1;
    levelData.value = "第" + levelData.value + "关";
  } catch (error) {
    levelData.value = "加载失败";
    console.error('请求关卡数失败:', error);
  }
};
stirGameArr()
difficultyInfo.value = getNameByLevel(gameId.value, difficulty.value.toString());
fetchDataFromServer();
let gameTime = ref(0) // 游戏总时长
let timerStart = null
let levelData = ref()
const startGame = () => {
  clearInterval(timerReady)
  gameStatus.value = 'start'
  timerStart = setInterval(() => {
    gameTime.value++
  }, 1000)
}
// 游戏结束
const endGame = () => {
  clearInterval(timerStart)
  gameStatus.value = 'end'
  console.log('游戏结束')
  console.log(errorTimes.value)
}

const timerIsOver = () => {
  showDialog({
    title: '温馨提示',
    content: '时间到，游戏结束！',
    customClass: 'timerOverDialog',
    noCancelBtn: true,
    onOk: () => {

    }
  })
}
const gameSuccess = () => {
  completeGame();
  showDialog({
    title: '恭喜',
    content: '恭喜您完成游戏，游戏结束！',
    customClass: 'timerOverDialog',
    noCancelBtn: true,
    onOk: () => {
      // goToScore()
    }
  })
}
const goToScore = () => {
  console.log('goToScore', userType.value)
  router.replace({
    name: 'getScore',
    params: {
      gameId: gameId.value,
      difficultyInfo: difficultyInfo.value,
      timer: gameTime.value,
      score: score.value,
      userType: userType.value,
      levelData: levelData.value,
    }
  })
}
onMounted(() => {
  // readyToGame()
})
// 计算用户的评分和类型
const calculateScoreAndType = () => {
  let ratingScore = 0;

  // 根据评级分数计算方式计算评级分数
  if (gameTime.value <= 26) {
    ratingScore = 80;
  } else if (gameTime.value <= 42) {
    ratingScore = 70;
  } else {
    ratingScore = 60;
  }

  // 根据难度和错误次数计算分数
  score.value = ratingScore + (total.value - errorTimes.value) / total.value * 20
  // // 保留整数
  // score.value = Math.round(score.value);
  // 保留两位
  score.value = score.value.toFixed(2)

  // 根据总分判断用户类型
  if (score.value >= 80) {
    userType.value = '优秀';
  } else if (score.value >= 60) {
    userType.value = '良好';
  } else {
    userType.value = '一般';
  }
}

// 获取难度，根据 level 获取对应的 name 值的函数
function getNameByLevel(gameId, level) {
  const game = storeState.gameList.find(item => item.id === gameId);
  if (game) {
    const levelItem = game.levelList.find(item => item.level === level);
    if (levelItem) {
      return levelItem.name;
    }
  }
  return ''; // 如果找不到匹配的 level，则返回空字符串
}

// 获取用户年龄，根据 level 获取对应的 age 值的函数
function getTipByName(gameId, level) {
  const game = storeState.gameList.find(item => item.id === gameId);
  if (game) {
    const levelItem = game.levelList.find(item => item.level === level);
    if (levelItem) {
      return levelItem.age;
    }
  }
  return ''; // 如果找不到匹配的 name，则返回空字符串
}

// 根据 userType 获取对应的报告详情
function userReport(userType) {
  return reportInfo.find(detail => detail.type === userType) || {};
}

// 发起 POST 请求
const sendDataToServer = async () => {
  let reportDetail = userReport(userType.value)
  let sub = ref(reportDetail.sub || '')
  // 创建一个对象来存储需要发送的参数
  const requestData = {
    'OpenID': openId,
    'TikTokUsername': userName,
    'UserName': userName,
    'Sex': sex,
    'Age': age,
    'TestApplication': stateInfo.gameName,
    'TestLevel': difficultyInfo.value,
    'UsageTime': gameTime.value,
    'UserType': userType.value,
    'ReportContent': sub.value,
    'ContactMethod': '',
    'Score': score.value,
  };
  try {
    const response = await axios.post(apiUrl + '/insertData', requestData);
    console.log('后端响应数据：', response.data);
  } catch (error) {
    console.error('请求失败:', error);
  }
};
const gameWarp = ref(null)

const computedGbWidth = computed(() => {
  let difficultyMax = 6 // 游戏最大
  if (gameWarp.value) {
    let long = Math.min(gameWarp.value.clientWidth, gameWarp.value.clientHeight)
    let min = Math.max(long / difficultyMax * difficulty.value, 100)
    return min + 'px'
  }
  return `calc(100% / ${difficultyMax} * ${difficulty.value})`
})
const computedBWidth = () => {
  return `calc(100% / ${difficulty.value})`
}

const errorNum = ref(0)
const errorTimes = ref(0)
let current = ref(0)
const gameBottleClick = (num) => {
  if (gameStatus.value === 'end') return
  if (num < current.value) return
  if (gameArrSorted.value[current.value] === num) {
    current.value++;
    errorNum.value = 0
    if (current.value === total.value) {
      completeBtn()
    }
  } else {
    errorNum.value = num
    errorTimes.value++
  }
}
// 重新测试
const reStart = () => {
  router.go(-1)
}
// 完成
const completeGame = () => {
  endGame();
  calculateScoreAndType();
  sendDataToServer();
}
// 完成按钮
const completeBtn = () => {
  completeGame()
  showReportPopup.value = true
}

const telNumber = storeState.phoneNumber
// 生成报告并减少用户测试次数
const decreaseTestInfo = async() => {
  console.log(gameId, telNumber)
  axios.post(apiUrl + '/decreaseTestInfo', {
    'telNumber': telNumber,
    'gameId': gameId.value
  }).then((res) => {
    console.log('减少用户测试次数成功', res)
  }).catch((err) => {
    console.log('减少用户测试次数失败', err)
  })
}
// 生成报告
const reporting = () => {
  decreaseTestInfo()
  goToScore()
}


const goBack = () => {
  router.go(-1)
}
onBeforeUnmount(() => {
  clearInterval(timerReady)
  clearInterval(timerStart)
})
</script>

<style scoped lang="less">
.schulte-game {
  width: 100%;
  height: 100%;
  overflow: auto;

  .schulte-content {
    .game-ready {
      width: 100%;
      height: 100%;
      padding: 0.5rem;

      .ready-ask {
        line-height: 0.5rem;
        font-size: 0.3rem;
        color: #505152;
        font-weight: bold;

        .first {
          margin-bottom: 0;
        }

        > p {
          margin-bottom: 0.5rem;
        }
      }

      .ready-time {
        font-size: 0.6rem;
        color: #4886FF;
        text-align: center;
        font-weight: bold;

        .normal-btn {
          margin-top: 0.8rem;
          width: 100%;
          padding: 0.2rem;
        }
      }
    }

    .game-start {
      width: 100%;
      height: 100%;
      padding: 0.8rem 0.55rem;
      display: flex;
      flex-direction: column;

      .top-word {
        margin-bottom: 0.5rem;
        text-align: center;

        .tip {
          margin: 0;
        }

        .time {
          margin-top: 0.15rem;
          font-size: 0.3rem;
          color: #BFC4CD;
          font-weight: bold;
        }
      }

      .game-box {
        flex: auto;
        overflow: auto;

        .game-warp {
          //aspect-ratio: 1;
          width: 100%;
          height: 100%;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;

          .games-bottle {
            //aspect-ratio: 1;
            margin: auto;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            border-top: 1px solid rgba(80, 81, 82, 1);
            border-left: 1px solid rgba(80, 81, 82, 1);

            .bottle {
              //aspect-ratio: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              border-right: 1px solid rgba(80, 81, 82, 1);
              border-bottom: 1px solid rgba(80, 81, 82, 1);
              font-size: 0.5rem;
              color: #505152;
              font-weight: bold;

              &.isClicked {
                color: #3B7DFF;
              }

              &.isError {
                color: #ff8c90;
              }
            }
          }
        }
      }

      .bottom-group {
        margin-top: 0.6rem;

        .operation {
          text-align: center;
          .normal-btn {
            width: 40%;
            padding: 0.2rem;
            &.reStart {
              margin-right: 0.3rem;
            }
          }
        }

        .back-line {
          margin-top: 0.6rem;
          text-align: center;
          color: #4886FF;
        }
      }
    }

    .tip {
      margin: 0.6rem 0;
      font-size: 0.36rem;
      color: #4886FF;
      font-weight: bold;
      text-align: center;
    }
  }
}
</style>
<style lang="less">
.timerOverDialog {
  .nut-dialog__footer {
    .nut-dialog__footer-ok {
      background: #4886FF;
    }
  }
}
</style>
