<template>
  <!--检测报告-->
  <nut-popup v-model:visible="showReportPopup" pop-class="report-popup">
    <div class="report-box">
      <p class="title popup-title">生成检测报告</p>
      <p class="tip">恭喜您完成测试，生成报告后无法再次检测，是否生成检测报告！</p>
      <div class="btn-group">
        <nut-button @click="reStart" class="normal-btn cancel" type="info" plain>重新测试</nut-button>
        <nut-button @click="reporting" class="normal-btn confirm" type="info">生成报告</nut-button>
      </div>
    </div>
  </nut-popup>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'
import { ref, defineEmits } from "vue";

const emits = defineEmits(['reporting'])
const router = useRouter()
let showReportPopup = ref(true) // 检测报告弹框
// 重新测试
const reStart = () => {
  router.go(-1)
}
// 生成报告
const reporting = () => {
  emits('reporting')
}
</script>

<style lang="less">
.report-popup {
  width: 80%;
  .report-box {
    padding: 0.4rem;
    text-align: center;
    .title {
      margin-bottom: 0.24rem;
    }
    .tip {
      font-size: 0.28rem;
      color: #999999;
    }
    .btn-group {
      margin-top: 0.48rem;

      .normal-btn {
        width: calc(50% - 0.15rem);
        height: 0.8rem;
        border-radius: 0.12rem;
      }
      .cancel {
        margin-right: 0.3rem;
      }
      .confirm {
        background: #4886FF;
      }
    }
  }
}
</style>
