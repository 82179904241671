<template>
  <div class="introduce">
    <back-title :title="pageTitle"></back-title>
    <div class="one-page">
      <div class="introduce-content flex-content">
        <p class="line-title">
          <span class="left"></span>
          <span class="center">{{ introduceList[gameId]?.title }}</span>
          <span class="right"></span>
        </p>
        <div class="intro-box">
          <p v-for="(item, index) in introduceList[gameId]?.intro || []" :key="index" class="intro">{{ index + 1 }}、{{ item }}</p>
          <span v-if="introduceList[gameId]?.remark" class="remark">{{ introduceList[gameId]?.remark }}</span>
        </div>
        <div class="intro-info">
          <!--          <nut-cell title="请选择城市" :desc="desc">-->
          <div class="item-info" @click="showSex = true">
            <span class="label">性别</span>
            <span class="val">
              <span>{{ sexValue || '请选择' }}</span>
              <i class="iconfont icon-arrow-next"></i>
            </span>
          </div>
          <div class="item-info" @click="showAge = true">
            <span class="label">年龄</span>
            <span class="val">
              <span>{{ ageValue || '请选择' }}</span>
              <i class="iconfont icon-arrow-next"></i>
            </span>
          </div>
          <nut-popup position="bottom" v-model:visible="showSex">
            <nut-picker v-model="sexPicker" :columns="sexCols" title="请选择性别" @confirm="sexConfirm" @cancel="showSex = false"></nut-picker>
          </nut-popup>
          <nut-popup position="bottom" v-model:visible="showAge">
            <nut-picker v-model="agePicker" :columns="ageCols" title="请选择年龄" @confirm="ageConfirm" @cancel="showAge = false"></nut-picker>
          </nut-popup>
        </div>
        <div class="bottom-group">
          <nut-button @click="goLevelChange" class="normal-btn" type="info">开始测试</nut-button>
          <p @click="goBack" class="back-line"><span>返回</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { ref, toRefs, reactive, shallowReactive, computed, onMounted, nextTick } from 'vue';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import backTitle from "@/components/backTitle";
import { useStore } from "vuex";
import {showDialog} from "@nutui/nutui";
import axios from "axios";

export default {
  name: 'GameIntroduce',
  components: {
    backTitle
  },
  data() {
    return {
      introduceList: [
        {
          name: '舒尔特',
          title: '舒尔特方格训练的优势',
          intro: [
              '培养注意力集中、分配、控制力；拓展视幅；极快视频；',
              '提高视觉稳定性、辨别力、定向搜索能力，练习的时间越长，看表所需的时间会越短；',
              '随着练习的深入，眼球的末梢视觉能高，不仅初学者可以有效地拓展视幅，加读节奏，锻炼眼睛快速认读。',
          ]
        },
        {
          name: '灵活性测试',
          title: '灵活性训练的优势',
          intro: [
            '采用专业的测试，深度分析灵活性程度；',
            '更精确地推荐合适自己的体育项目；',
            '更好地挖掘运动员潜力，帮助教练更了解运动员；',
            '确保您在运动中获得最佳的体验和发展。',
          ],
          remark: '注：通过反复测试，也可以训练青少年的灵活型，有效提升灵活性表现。'
        },
        {
          name: '脑力反映测试',
          title: '脑力反映测试的优势',
          intro: [
            '采用专业的的测试，深度分析人类高级神经活动的基本特征；',
            '更精确地推荐合适自己的体育项目；',
            '更好地挖掘运动员潜力，帮助教练更了解运动员；',
            '确保您在运动中获得最佳的体验和发展。',
          ],
          remark: '注：本测试根据人的大脑皮质机能能力的发展和神经细胞兴奋与抑制过程的活动规律而设计的，测试难度高，重复测试效果好。'
        }
      ]
    }
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const storeState = store.state

    let gameId = ref(route.params.gameId || '0')
    const pageTitle = ref('')
    pageTitle.value = storeState.gameList[gameId.value].gameName

    const openId = storeState.openId
    const goLevelChange = () => {
      if(!sexValue.value || !ageValue.value) {
        showDialog({
          title: '提示',
          content: '请先填写完整的信息！',
          customClass: 'timerOverDialog',
          noCancelBtn: true,
          onOk: () => {}
        })
        return
      }
      store.commit('updateUserInfo', {
        sex: sexValue.value,
        age: ageValue.value
      })
      console.log(store)
      router.push({
        name:'levelChange',
        params: {
          gameId: gameId.value
        }
      })
    }
    const goBack = () => {
      router.go(-1)
    }

    // 身份选择
    const showSex = ref(false)
    const showAge = ref(false)
    const sexValue = ref(storeState.userInfo.sex)
    const ageValue = ref(storeState.userInfo.age)
    const sexPicker = ref()
    const agePicker = ref()
    const sexCols = ref([
      { text: '男', value: 'man' },
      { text: '女', value: 'woman' },
    ])
    const ageCols = ref([
      {
        text: '2-4岁',
        value: 1,
      },
      {
        text: '5-7岁',
        value: 2,
      },
      {
        text: '8-12岁',
        value: 3,
      },
      {
        text: '13岁以上',
        value: 4,
      },
    ])

    const sexConfirm = ({ selectedValue, selectedOptions }) => {
      sexValue.value = selectedOptions[0].text
      showSex.value = false
    };

    const ageConfirm = ({ selectedValue, selectedOptions }) => {
      ageValue.value = selectedOptions[0].text
      showAge.value = false
    };


    return {
      gameId,
      pageTitle,
      goLevelChange,
      goBack,
      showSex,
      showAge,
      sexValue,
      ageValue,
      sexPicker,
      agePicker,
      sexCols,
      ageCols,
      sexConfirm,
      ageConfirm,
    }
  }
}
</script>

<style scoped lang="less">
.introduce {
  width: 100%;
  height: 100%;
  overflow: auto;
  .introduce-content {
    padding: 0.6rem 0.6rem 1rem;
    flex-direction: column;
    .intro-box {
      flex: auto;
      margin: 0.6rem 0;
      overflow: auto;
      font-size: 0.3rem;
      color: #505152;
      p.intro {
        font-weight: bold;
        line-height: 0.5rem;
        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }
      span.remark {
        display: inline-block;
        font-size: 0.24rem;
        color: #B4B5B7;
        margin-top: -0.45rem;
      }
    }
    .intro-info {
      margin-bottom: 0.8rem;
      .item-info {
        height: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.3rem;
        border-bottom: 1px solid #E5E5E5;
        >.label {
          color: #222;
        }
        >.val {
          color: #999;
        }
      }
    }
    .bottom-group {
      .normal-btn {
        width: 100%;
        padding: 0.2rem;
      }
      .back-line {
        margin-top: 0.6rem;
        text-align: center;
        color: #4886FF;
      }
    }
  }
}
.timerOverDialog {
  .nut-dialog__footer {
    .nut-dialog__footer-ok {
      background: #4886FF;
    }
  }
}
.intro-info :deep(.nut-picker__right) {
  color: #4886FF;
}
</style>
