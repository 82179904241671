import {createStore} from 'vuex'

export default createStore({
    state: {
        gameList: [
            {
                id: '0',
                img: 'game1',
                title: '专注力测试',
                sub: '舒尔特方格',
                devEnd: true,
                gameName: '舒尔特方格',
                levelList: [
                    {
                        name: '初级',
                        level: '3',
                        tip: '建议2-4岁儿童测试',
                    },
                    {
                        name: '中级',
                        level: '4',
                        tip: '建议5-7岁儿童测试',
                    },
                    {
                        name: '高级',
                        level: '5',
                        tip: '建议8-12岁儿童测试',
                    },
                    {
                        name: '困难',
                        level: '6',
                        tip: '建议13岁以上儿童测试',
                    },
                ],
            },
            {
                id: '1',
                img: 'game2',
                title: '数字划消法',
                sub: '数字',
                devEnd: false,
                gameName: '数字划消法',
                levelList: [
                    {
                        name: '入门',
                        level: '3',
                        tip: '建议2-4岁儿童测试',
                    },
                    {
                        name: '简单',
                        level: '4',
                        tip: '建议5-7岁儿童测试',
                    },
                    {
                        name: '适中',
                        level: '5',
                        tip: '建议8-12岁儿童测试',
                    },
                    {
                        name: '困难',
                        level: '6',
                        tip: '建议13岁以上儿童测试',
                    },
                    {
                        name: '挑战',
                        level: '7',
                        tip: '建议13岁以上儿童测试',
                    },
                ],
            },
            {
                id: '2',
                img: 'game3',
                title: '脑力反应测试',
                sub: '反应',
                devEnd: false,
                gameName: '脑力反应测试',
                levelList: [
                    {
                        name: '简单',
                        level: '4',
                        tip: '建议5-7岁儿童测试',
                    },
                    {
                        name: '适中',
                        level: '5',
                        tip: '建议8-12岁儿童测试',
                    },
                    {
                        name: '困难',
                        level: '6',
                        tip: '建议13岁以上儿童测试',
                    },
                ],
            },
            {
                id: 'report',
                img: 'report',
                title: '检测报告获取',
                sub: '报告',
                devEnd: true,
                gameName: '检测报告获取',
            },
            {
                id: null,
                img: 'nogame',
                title: '敬请期待...',
                sub: '',
                devEnd: false
            },
        ],
        userInfo: {
            sex: '',
            age: 0
        },
        userName: '', // 微信用户名
        avatar: '',//微信用户头像
        openId: '', //微信小程序用户的唯一标识符,
        phoneNumber: '',
        ifInitialized: false,
        reportDetails: [
            {
                type: '稳定型',
                title: '属于稳定型运动选手',
                sub: '反应较灵活型者慢、细心、踏实、肯于钻研、思维准确、具有长时间稳定的工作能力。学习动作慢于灵活型，但是掌握动作较牢固，不易忘记，消退，正常情况下临场发挥好，但应变能力不及灵活型。'
            },
            {
                type: '亚稳定型',
                title: '属于亚稳定型运动选手',
                sub: '亚稳定性是介于稳定型与灵活型的一种，学习动作慢于灵活型，但是掌握动作较牢固，不易忘记，消退，正常情况下临场发挥好，但应变能力不及灵活型。'
            },
            {
                type: '兴奋型',
                title: '属于兴奋型运动选手',
                sub: '学习掌握动作速度快，但粗心，动作细节易忽视，临场比赛易受环境因素的影响，起伏较大，稳定性较差。'
            }
        ]
    },
    getters: {},
    mutations: {
        updateUserInfo(state, data) {
            state.userInfo = data
        },
        setUsername(state, userName) {
            state.userName = userName;
        },
        setAvatar(state, avatar) {
            state.avatar = avatar;
        },
        setOpenId(state, OpenId) {
            state.openId = OpenId;
        },
        setPhoneNumber(state, phoneNumber) {
            state.phoneNumber = phoneNumber;
        },
        setIfInitialized(state, value) {
            state.ifInitialized = value;
        },
    },
    actions: {},
    modules: {}
})
