<template>
  <div class="verifyLogin">
    <div class="verify-top">
      <p class="mb-word">“ 运动潜能 ”</p>
      <p class="mb-word">匹配大师</p>
      <p class="mb-subWord">发现你的内在体育天赋</p>
    </div>
    <p class="line-title">
      <span class="left"></span>
      <span class="center">运动员选材测试</span>
      <span class="right"></span>
    </p>
    <div class="verify-content">
      <p class="content-title">体验功能可选择游客登录</p>
      <div class="content-btnGroup">
        <nut-button class="less-radius-btn" type="info" @click="phoneLogin">手机登录</nut-button>
        <nut-button class="less-radius-btn" type="info" @click="touristLogin">游客登录</nut-button>
      </div>
    </div>
  </div>
</template>

<script setup>
const phoneLogin = () => {}
const touristLogin = () => {}
</script>

<style scoped lang="less">
.verifyLogin {
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  background: #FFF url("../assets/image/bg.png") no-repeat 100%/100% 100%;
  .verify-top {
    flex: none;
    height: 35%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .verify-content {
    margin-top: 1.5rem;
    font-size: 0.24rem;
    text-align: center;
    .content-title {
      color: #000;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
    .content-btnGroup {
      .less-radius-btn {
        margin: 0 0.15rem;
        padding: 0 0.5rem;
      }
    }
  }
}
</style>
