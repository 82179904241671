(function (width) {
  var doc = width.document,
      element = doc.documentElement,
      i = 750,
      d = i / 100,
      o = "orientationchange" in width ? "orientationchange" : "resize",
      a = function () {
        var width = element.clientWidth || 375;
        width > 750 && (width = 750);
        element.style.fontSize = width / d + "px"
      };
  doc.addEventListener && (width.addEventListener(o, a, !1), doc.addEventListener("DOMContentLoaded", a, !1))
})(window);
