<template>
  <div class="back-title">
    <i class="iconfont icon-arrow-back" @click="goBack"></i>
    <span class="title">{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: 'BackTitle',
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="less">
.back-title {
  height: 0.8rem;
  line-height: 0.8rem;
  font-size: 0.3rem;
  color: #fff;
  text-align: center;
  position: relative;
  .icon-arrow-back {
    font-size: 0.4rem;
    position: absolute;
    left: 22px;
  }
}
</style>
