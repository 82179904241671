<template>
  <div class="get-score">
    <back-title :title="stateInfo.gameName"></back-title>
    <div class="one-page">
      <div class="score-content flex-content">
        <div class="user-info">
          <img :src="avatar" alt="">
          <p>{{ userName }}</p>
        </div>
        <div class="my-score">
          <p class="my-score__tip">您本次舒尔特注意力训练分数为</p>
          <div class="my-score__no">
            <img src="../assets/image/starL.png" alt="">
            <div class="score-box">
              <span class="score-num">{{ score }}</span>
              <span class="score-unit">分</span>
            </div>
            <img src="../assets/image/starR.png" alt="">
          </div>
        </div>
        <div class="my-result">
          <p class="result-tip">
            属于：{{ userType }}
          </p>
          <div class="result-info">
            <div class="info-item">
              <p>{{ difficultyInfo }}</p>
              <p>难度</p>
            </div>
            <div class="info-item">
              <p>{{ levelData }}</p>
              <p>关卡</p>
            </div>
            <div class="info-item">
              <p>{{ timer }}秒</p>
              <p>时间</p>
            </div>
          </div>
        </div>
        <div class="operation">
          <div class="btn-group">
            <nut-button @click="lookTestReport" class="normal-btn look" type="info">查看检测报告</nut-button>
          </div>
          <btn-tab location="getScore"></btn-tab>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs, reactive, shallowReactive, computed, onMounted, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import backTitle from "@/components/backTitle";
import btnTab from "@/components/btnTab";
import axios from 'axios';

const route = useRoute()
const router = useRouter()
const store = useStore()
const storeState = store.state

let gameId = ref(route.params.gameId || '0')
let difficultyInfo = ref(route.params.difficultyInfo || '0')
let score = ref(route.params.score || '0')
let timer = ref(route.params.timer || '0')
let userType = ref(route.params.userType || "优") // 用户的类型
let levelData = ref(route.params.levelData || '加载中...')
const stateInfo = storeState.gameList[gameId.value]

const userName = storeState.userName
const avatar = storeState.avatar

const apiUrl = process.env.VUE_APP_API_URL


onMounted(() => {})

const goOnGame = () => {
  router.push({
    name: 'levelChange',
    params: {
      gameId: gameId.value
    }
  })
}
const lookTestReport = () => {
  router.push({
    name: 'testReport',
    params: {
      gameId: gameId.value,
      difficultyInfo: difficultyInfo.value,
      timer: timer.value,
      score: score.value,
      userType: userType.value,
      testDate: new Date().toLocaleDateString('zh-CN', { timeZone: 'Asia/Shanghai' })
    }
  })
}
const replay = () => {
  router.go(-1)
}
const goHome = () => {
  router.push({
    path: '/?username=' + userName + '&avatar=' + avatar,
  })
}
const sharing = () => {
}

</script>

<style scoped lang="less">
.get-score {
  width: 100%;
  height: 100%;
  overflow: auto;

  .score-content {
    padding: 0.6rem 0.5rem 0.5rem;
    flex-direction: column;
    text-align: center;

    .user-info {
      margin-bottom: 0.7rem;

      img {
        width: 1.2rem;
        height: 1.2rem;
        margin-bottom: 0.2rem;
        border-radius: 50%;
      }

      p {
        font-size: 0.32rem;
        font-weight: bold;
      }
    }

    .my-score {
      margin-bottom: 1rem;

      .my-score__tip {
        font-size: 0.3rem;
        font-weight: bold;
        margin-bottom: 0.4rem;
      }

      .my-score__no {
        width: 60%;
        margin: auto;
        display: flex;
        justify-content: center;

        img {
          height: 1.2rem;
        }

        .score-box {
          flex: none;
          color: #4886FF;
          font-weight: bold;
          line-height: 1;

          .score-num {
            font-size: 1.2rem;
          }

          .score-unit {
            font-size: 0.3rem;
          }
        }
      }
    }

    .my-result {
      flex: auto;
      margin-bottom: 0.5rem;

      .result-tip {
        font-size: 0.3rem;
        color: #4886FF;
        font-weight: bold;
        margin-bottom: 0.3rem;
      }

      .result-info {
        background: #F4F7FF;
        border-radius: 0.16rem;
        display: flex;

        .info-item {
          flex: 1;
          padding: 0.2rem;
          text-align: center;

          > p:first-child {
            font-size: 0.32rem;
            color: #2BDF42;
            font-weight: bold;
          }

          > p:last-child {
            font-size: 0.3rem;
            color: #BFC4CD;
            font-weight: bold;
          }
        }
      }
    }

    .operation {
      .btn-group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.4rem;

        .normal-btn {
          width: 45%;
          padding: 0.22rem 0;
        }

        .left-btn {
          color: #505152;
        }
        .look {
          width: 100%;
        }
      }
    }
  }
}
</style>
