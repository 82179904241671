<template>
  <div class="btn-tab">
    <!--<div class="tab-item" @click="replay">-->
    <!--  <img src="../assets/image/replay.png" alt="">-->
    <!--  <p>重新开始</p>-->
    <!--</div>-->
    <div class="tab-item" @click="goHome">
      <img src="../assets/image/home.png" alt="">
      <p>主页</p>
    </div>
    <div class="tab-item" @click="sharing">
      <img src="../assets/image/share.png" alt="">
      <p>分享</p>
    </div>
    <div class="tab-item" @click="download">
      <img src="../assets/image/downLoad.png" alt="">
      <p>下载</p>
    </div>
  </div>
</template>

<script setup>


import { useRoute, useRouter } from 'vue-router'
import wx from 'weixin-js-sdk'
import html2canvas from 'html2canvas'
import axios from 'axios';
import { useStore } from 'vuex';
import { defineProps } from 'vue';


const router = useRouter()
const storeState = useStore().state
const replay = () => {
  router.go(-1)
}
const goHome = () => {
  router.push({
    path: '/',
  })
}

const props = defineProps({
  location:{
    type: String,
    default: ''
  }
})

const apiUrl = process.env.VUE_APP_API_URL
const captureHtml = async (shootHeight) => {
  return await html2canvas(document.body, {
    useCORS: true,
    scale: 1,
    height: shootHeight,
    windowHeight: shootHeight,
  });
}

const uploadImage = async (base64) => {
  return await axios.post(apiUrl + '/uploadImage', {
    image: base64,
    telNumber: storeState.phoneNumber
  });
}

const generateHtmlimage = async () => {
  try {
    let shootHeight = 0;
    if (props.location === 'getScore') 
    {
      shootHeight = document.body.scrollHeight;
    } 
    else if (props.location === 'testReport') 
    {
      let pageHeight = document.getElementsByClassName('test-report')[0].clientHeight;
      let wordsHeight = document.getElementsByClassName('report-word')[0].clientHeight;
      let leftHeight = pageHeight - wordsHeight;
      shootHeight = document.getElementsByClassName('report-word')[0].scrollHeight + leftHeight;
    }

    const canvas = await captureHtml(shootHeight);
    const img = canvas.toDataURL('image/png');
    console.log(img);
    const base64 = img.split(',')[1];

    const response = await uploadImage(base64);
    return response;
  } catch (error) {
    console.error(error);
  }
}

const sharing = () => {
  generateHtmlimage().then(() => {
    wx.miniProgram.navigateTo({
      url: '/pages/downloadImage/downloadImage' + '?ifShareImage=1'
    })
  })
}

const download = () => {
  generateHtmlimage().then(() => {
    wx.miniProgram.navigateTo({
      url: '/pages/downloadImage/downloadImage' + '?ifSaveImage=1'
    })
  })
}
</script>

<style scoped lang="less">

.btn-tab {
  display: flex;
  text-align: center;
  .tab-item {
    flex: 1;
    img {
      width: 0.8rem;
      margin-bottom: 0.1rem;
    }
    p {
      font-size: 0.2rem;
      color: #B0B0B0;
      font-weight: 500;
    }
  }
}
</style>
