<template>
  <div class="reaction-game">
    <back-title :title="pageTitle"></back-title>
    <div class="one-page">
      <div class="reaction-content ready-content flex-content">
        <div class="game-ready" v-if="gameStatus === 'ready'">
          <p class="line-title">
            <span class="left"></span>
            <span class="center">方法</span>
            <span class="right"></span>
          </p>
          <p class="tip">在330秒内根据页面提示点击对应符号</p>
          <div class="ready-ask">
            <p class="first">要求：</p>
            <p>1、视点自然放在表中心</p>
            <p>2、尽量减少眨眼次数；尽量不要转动眼球</p>
            <p>3、若数字的展示为<span style="color: #ff8c90;">红色</span>，重新点击正确数字即可</p>
          </div>
          <div class="ready-time">
            <nut-button @click="startGame" class="normal-btn" type="info">开始</nut-button>
          </div>
        </div>
        <div class="game-start" v-if="gameStatus === 'start' || gameStatus === 'end'">
          <div class="top-word">
            <p class="tip">
              <span>从左至右依次点击符号</span>
              <i></i>
            </p>
            <p class="time">倒计时：{{ gameTime }}s</p>
          </div>
          <div class="game-box">
            <div class="contrast-icon">
              <div class="icon-block" v-for="(item, index) in gameArr" :key="index">
                <i class="iconfont" :class="item.defaultIcon[0]"></i>
                <i class="iconfont" :class="item.defaultIcon[1]"></i>
              </div>
            </div>
            <div class="game-warp" ref="gameWarp">
              <div class="games-bottle">
                <div class="games-line" v-for="(item, index) in gameArr" :key="index">
                  <div
                      class="games-block"
                      v-for="(block, blockIndex) in item.gameIcon"
                      :key="blockIndex"
                      @click="gameBottleClick(item, block)"
                      :class="{ isClicked: block.isClicked && !block.isError, isError: block.isError  }"
                  >
                    <i class="iconfont" :class="block.icon"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-group">
            <p class="operation">
              <nut-button @click="reStart" class="normal-btn reStart" type="info" plain>重新测试</nut-button>
              <nut-button @click="completeGame" class="normal-btn" type="info" :disabled="isButtonDisabled">完成</nut-button>
            </p>
            <p @click="goBack" class="back-line"><span>返回</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--检测报告-->
  <report-popup v-if="showReportPopup" @reporting="reporting"></report-popup>
</template>

<script setup>
import {ref, toRefs, reactive, shallowReactive, computed, onMounted, onBeforeUnmount, nextTick} from 'vue';
import {useRoute, useRouter} from 'vue-router'
import {useStore} from 'vuex'
import {showDialog} from '@nutui/nutui';
import backTitle from "@/components/backTitle";
import axios from "axios";

const route = useRoute()
const router = useRouter()
const store = useStore()
const storeState = store.state

let gameId = ref(route.params.gameId || '0')
let difficulty = ref(Number(route.params.difficulty || '0')) // 难度即方格行列数
const stateInfo = storeState.gameList[gameId.value]
const pageTitle = ref(stateInfo.gameName)
let showReportPopup = ref(false) // 检测报告弹框

let gameStatus = ref('ready') // 游戏状态：ready start end
let gameTime = ref(70) // 游戏总时长
const gameWarp = ref(null)
let isButtonDisabled = ref(true) //完成按钮初始为禁用，直到用户完成游戏

const goBack = () => {
  router.go(-1)
}

const startGame = () => {
  gameStatus.value=  'start'
}

const gameArr = ref([])

const initGame = (difficulty) => {
  let row = 100
  let col = difficulty * 2
  let iconArr = ['icon-icon-a', 'icon-icon-b', 'icon-icon-c', 'icon-icon-d', 'icon-icon-e',
    'icon-icon-f', 'icon-icon-g', 'icon-icon-h', 'icon-icon-i', 'icon-icon-j']
  let arr = []
  for (let i = 0; i < row; i++) {
    let gameIcon = []
    for (let j = 0; j < col; j++) {
      gameIcon.push({
        icon: iconArr[Math.floor(Math.random() * 10)],
        isError: false,
        isClicked: false,
      })
    }
    let defaultIcon = [iconArr[Math.floor(Math.random() * 10)], iconArr[Math.floor(Math.random() * 10)]]
    arr.push({
      defaultIcon,
      gameIcon
    })
  }
  gameArr.value = arr
  console.log(gameArr)
}

onMounted(() => {
  initGame(difficulty.value)
})

const gameBottleClick = (item, block) => {
  if (block.isClicked) return
  block.isClicked = true
  if (!item.defaultIcon.includes(block.icon)) {
    block.isError = true
  }
}

// 重新测试
const reStart = () => {
  router.go(-1)
}
// 完成
const completeGame = () => {
  showReportPopup.value = true
}
</script>

<style scoped lang="less">
.reaction-game {
  width: 100%;
  height: 100%;
  overflow: auto;

  .game-start {
    .game-box {
      flex: auto;
      overflow: auto;
      display: flex;
      align-items: flex-start;

      .contrast-icon {
        width: 0.8rem;
        background: #F4F7FF;
        border-top: 1px solid transparent;
        >.icon-block {
          height: 0.8rem;
          line-height: 0.8rem;
          >i {
            font-size: 0.4rem;
            color: #4886FF;
          }
        }
      }

      .game-warp {
        width: calc(100% - 0.8rem);
        overflow-x: auto;



        .games-bottle {
          width: 100%;
          height: 100%;
          overflow-x: auto;
          border-left: 1px solid rgba(80, 81, 82, 1);

          .games-line {
            width: 100%;
            height: 0.8rem;
            display: flex;
            justify-content: flex-start;
            &:first-child {
              .games-block {
                border-top: 1px solid rgba(80, 81, 82, 1);
              }
            }
            .games-block {
              width: 0.8rem;
              line-height: normal;
              flex: none;
              border-right: 1px solid rgba(80, 81, 82, 1);
              border-bottom: 1px solid rgba(80, 81, 82, 1);
              color: #000000;
              >i {
                font-size: 0.8rem;
              }
              &.isClicked {
                color: #3B7DFF;
              }

              &.isError {
                color: #ff8c90;
              }
            }
          }
        }
      }
    }
  }

}
</style>
<style lang="less">
.timerOverDialog {
  .nut-dialog__footer {
    .nut-dialog__footer-ok {
      background: #4886FF;
    }
  }
}
</style>
